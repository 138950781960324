<template>
    <div class="">
        
      <section class="padding">
        <div class="container">

          <div class="row">
            <div class="col-sm-12 text-center">

              <h2 v-if="pgLoading" class="webkit-animation webkit-25per-35 mauto mb-5"></h2>
              <p v-if="pgLoading" class="heading_space webkit-animation webkit-50per-25 mauto mb-25"></p>

              <h2 v-if="!pgLoading" 
                  :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                  v-html="($i18n.locale == 'ar') ? setting.title_ar : setting.title_en">
              </h2>
              <p  v-if="!pgLoading" 
                  class="heading_space bottom15" 
                  :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                  v-html="($i18n.locale == 'ar') ? setting.body_ar : setting.body_en">
              </p>

            </div>
          </div>

          <div v-if="pgLoading" class="col-sm-12 row gty">
            <div v-for="(cat, index) in 5" 
                :key="index"
                :class="(index == 1) ? 'col-lg-8 gd gd-exp' : 'col-lg-4 gd'">
                <div class="webkit-animation webkit-100per-390"></div>
                <div class="grid-caption">
                  <h3 class="webkit-animation webkit-150-50 mb-5"></h3>
                  <p class="webkit-animation webkit-100-30 mauto"></p>
                </div>
            </div>
          </div>

          <div v-if="!pgLoading" class="col-sm-12 row gty">
            <div 
                v-for="(item, index) in items" 
                :key="index"
                :class="(index == 1) ? 'col-lg-8 gd gd-exp' : 'col-lg-4 gd'">

              <router-link v-if="$i18n.locale == 'en'" :to="'/properties?type='+item.slug" style="width: 100%">

                <img v-lazy="(item.image) ? item.image.url : ''"
                     :src="(item.image) ? item.image.url : ''" 
                     :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.alt_ar : item.image.alt_en : ''"
                     :title="(item.image) ?($i18n.locale == 'ar') ? item.image.title_ar : item.image.title_en : ''">

                <div class="grid-caption" :class="($i18n.locale == 'ar') ? 'rtl' : ''">
                  <h3 
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                      v-html="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
                  </h3>
                  <span 
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                      {{ item.count_no }} {{ $t('app.properties') }}
                  </span>
                </div>
              </router-link>

              <router-link v-if="$i18n.locale == 'ar'" :to="'/properties?type='+item.slug+'&lang=ar'" style="width: 100%">

                <img v-lazy="(item.image) ? item.image.url : ''"
                     :src="(item.image) ? item.image.url : ''"
                     :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.alt_ar : item.image.alt_en : ''"
                     :title="(item.image) ?($i18n.locale == 'ar') ? item.image.title_ar : item.image.title_en : ''">

                <div class="grid-caption" :class="($i18n.locale == 'ar') ? 'rtl' : ''">
                  <h3
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                          v-html="($i18n.locale == 'ar') ? item.title_ar : item.title_en">
                  </h3>
                  <span
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                      {{ item.count_no }} {{ $t('app.properties') }}
                  </span>
                </div>
              </router-link>

            </div>
          </div>

            <LoadMoreButton
                :pgLoading="pgLoading"
                :hasMore="hasMore"
                :btnLoading="btnLoading"
                
                v-on:loadMoreClicked="handleLoadMore">
            </LoadMoreButton>

          
        </div>
      </section>

    </div>
</template>


<script>
export default {
    name: 'PropertyType',
    components: {
        LoadMoreButton: () => import('@/components/LoadMoreButton.vue'),
    },
    data(){
        return {
            pgLoading: true,
            hasMore: false,
            btnLoading: false,
            btnClicked: false,
            pageNo: 1,

            page: '',
            setting: '',
            items: [],
        }
    },
    mounted() {},
    created() {
        //
        if(localStorage.getItem('locale')) {
            this.locale = localStorage.getItem('locale');
        }

        this.fetchData(true);
    },
    methods: {
        //
        fetchData(loading=false) {
            if(loading) {
                this.pgLoading = true;
            }
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            let vm = this;
            const options = {
                url: window.baseURL+'/propertyTypes',
                method: 'GET',
                data: {},
                params: {
                    page: this.pageNo,
                    paginate: 5
                }
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.btnLoading = false;
                this.page = res.data.page;
                this.setting = res.data.setting;
                  
                if(this.btnClicked) {
                    this.items = vm.items.concat(res.data.items);
                } else {
                    this.items = res.data.items;
                }

                this.btnClicked = false;

                if(res.data.pagination.next_page > 0) {
                  
                    this.hasMore = true;
                } else {
                    this.hasMore = false;
                }

            })
            .catch(() => { })
            .finally(() => { });
        },

        handleLoadMore() {
            this.btnClicked = true;
            this.btnLoading = true;
            this.pageNo++;
            this.fetchData();
        },

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gty { padding: 0 }
.gty .gd-exp { padding-right: -5px }
.gty .gd { 
    padding-bottom: 30px; 
}
.gty a {
    display: inline-block; 
    overflow: hidden; 
}
.gty a img { 
    width: 100%; 
    height: 385px; 
    display: block; 
    transition: transform .2s; 
}
.gty a img:hover { 
  transform: scale(1.1); 
  transform-origin: 50% 50%; 
}
.gty .gd .grid-caption { 
    color: #fff;
    text-align: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
